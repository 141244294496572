import React, { useEffect } from 'react'
import SignupNewsletter from './sign-up-newsletter'
import AboutUsClientAcqusition from "../images/svg-icons/about-us-client-acqusition.svg"
import AboutUsFinancing from "../images/svg-icons/about-us-financing.svg"
import AboutUsStratergy from "../images/svg-icons/about-us-stratergy.svg"
import AboutUsTeam from "../images/svg-icons/about-us-team.svg"
import { scrollAnimation } from "../lib/utils"
import AboutHero from "./about_hero"

export default function AboutUS() {

    useEffect(() => {
        scrollAnimation()


    }, [])

    return (


        <>
            <AboutHero />
            <div className=" relative flex flex-col md:flex-row bg-sky-50  ">

                <div className=" w-full md:w-20v   ">

                    <div className="hidden absolute h-44 w-44 top-80% -left-4   overflow-hidden rounded-full lg:block  bg-gradient-to-br from-orange-100 shadow-2xl to-orange-300 " aria-hidden="true">
                    </div>
                    <div className="hidden absolute h-48 w-48 -top-10% -left-3   overflow-hidden rounded-full lg:block bg-gradient-to-br from-transparent to-primary-blue   shadow-2xl " aria-hidden="true">
                    </div>
                    <div className="hidden absolute h-44 w-44 top-25% left-44   overflow-hidden rounded-full lg:block bg-gradient-to-br from-gray-400 to-gray-300   shadow-2xl opacity-50" aria-hidden="true">
                    </div>

                </div>

                <div className="bg-sky-50  w-full md:w-70v">

                    <div className="bg-sky-50 flex flex-col justify-start items-center px-8 md:px-16 lg:px-32  gap-16 pb-32">
                        {/* <div className="max-w-8xl font-serif mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                            <h2 className="text-4xl font-light tracking-wide text-primary-blue   sm:text-5xl">
                                <p className="block leading-tight">An <span className="text-orange-600"> early-stage VC </span>firm, we partner with champion founders to build <span className="text-orange-600"> high-growth</span>, tech-driven companies</p>
                            </h2>
                        </div> */}

                        <h2 className=" text-justify ">
                            <span className="block font-body1 "> Our relationships go beyond the captable. We walk the talk. You’ll see us roll up our sleeves or rope in our connections - every time you need us. We are led by these simple principles: </span>
                        </h2>

                        <h2 className=" text-left  js-scroll js-animate-fade-in-down ">
                            <span className="block font-heading1">Founder First</span>
                            <p className="block font-body1">It really is all about you - the hungry founder out to get a bigger piece of the industry pie! You are the single biggest factor influencing our decision to invest in your startup. We believe that your vision for a better world and the ability to embrace failure in the same stride as success make for invaluable assets.</p>
                        </h2>
                       

                        <div className={`w-full h-1 border-t border-orange-300 `} />

                        <h2 className=" text-left js-scroll js-animate-fade-in-down">
                            <span className="block font-heading1 ">  First Institutional Capital </span>

                            <span className="block font-body1">Have a game-changing idea? The first round’s on us.
Not just your first money, we are with you through all your firsts - your first hire, first customer, first product. We are alongside you in every step of your entrepreneurial journey—from as early as when your idea is still on paper.</span>
                        </h2>

                        <div className={`w-full h-1 border-t border-orange-300 `} />


                        <h2 className=" text-left js-scroll js-animate-fade-in-down">
                            <span className="block font-heading1">Sector Agnostic
                            </span>
                            <span className="block font-body1">Being in a league of your own is what attracts us - domain no bar. We invest in sectors representing enormous market opportunities, where significant value can be unlocked through technology, business model innovation, and market disruption. </span>
                        </h2>

                        <div className={`w-full h-1 border-t border-orange-300 `} />

                    </div>
                </div>

            </div>

            <h2 className="max-w-5xl mx-auto font-heading1 text-center  js-scroll js-animate-fade-in-down">
                We help startups create an environment geared for exponential growth. Beyond the captable, we assist in ...
            </h2>

            {/* <h2 className="max-w-5xl mx-auto text-center font-heading1 mt-8  js-scroll js-animate-fade-in-down">Beyond the cap table, we help you with
            </h2> */}

            <div className="flex flex-col lg:flex-row justify-center py-16  js-scroll js-animate-fade-in-down ">
                <div className="mx-10 flex flex-col justify-center items-center mt-16">
                    <div className="   h-20 w-20 flex justify-center items-center js-scroll js-animate-vote">
                        <AboutUsClientAcqusition />
                    </div>
                    <div className="max-w-3xl mx-auto text-center mt-8  ">
                        <h2 className="font-body1 text-primary-blue">
                            <span className="block ">Client and Partner</span>
                            <span className="block ">Acquisition</span>

                        </h2>
                    </div>

                </div>
                <div className="mx-10 flex flex-col justify-center items-center mt-16">
                    <div className="   h-20 w-20 flex justify-center items-center js-scroll js-animate-vote">
                        <AboutUsFinancing />
                    </div>
                    <div className="max-w-3xl mx-auto text-center mt-8  ">
                        <h2 className="font-body1 text-primary-blue">
                            <span className="block ">Further Rounds of </span>
                            <span className="block ">Financing</span>

                        </h2>
                    </div>
                </div>
                <div className="mx-10 flex flex-col justify-center items-center mt-16 ">
                    <div className="   h-20 w-20 flex justify-center items-center js-scroll js-animate-vote">
                        <AboutUsStratergy />
                    </div>
                    <div className="max-w-3xl mx-auto text-center mt-8   ">
                        <h2 className="font-body1 text-primary-blue ">
                            <span className="block ">Strategy and Hands-on</span>
                            <span className="block ">Guidance</span>

                        </h2>
                    </div>
                </div>
                <div className="mx-10 flex flex-col justify-center items-center mt-16">
                    <div className="   h-20 w-20 flex justify-center items-center js-scroll js-animate-vote">
                        <AboutUsTeam />
                    </div>
                    <div className="max-w-3xl mx-auto text-center mt-8  ">
                        <h2 className="font-body1 text-primary-blue ">
                            <span className="block ">Team</span>
                            <span className="block ">Building</span>

                        </h2>
                    </div>
                </div>


            </div>

        </>
    )
}