
import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'

const navigation = [
    { name: 'About', icon: HomeIcon, href: '/about-us', current: true },
    { name: 'Team', icon: UsersIcon, href: '/team', count: 3, current: false },
    { name: 'Portfolio', icon: FolderIcon, href: '/portfolio', count: 4, current: false },
    { name: 'News', icon: CalendarIcon, href: '/news', current: false },
    { name: 'Blog', icon: InboxIcon, href: '/blogs', count: 12, current: false },
    { name: 'Contact Us', icon: ChartBarIcon, href: 'contact-us', current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Sidebar() {
    const [open, setOpen] = useState(false)

    return (
        <>
            <div className="flex justify-end ...">
                <svg onClick={() => setOpen(true)} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </div>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setOpen}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Dialog.Overlay className="absolute inset-0" />

                        <div className="fixed inset-y-0 right-0 mt-5 max-w-full flex">
                            <Transition.Child
                                as={Fragment}

                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <nav className=" flex-1 px-2 space-y-1 bg-black w-56" aria-label="Sidebar">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className={classNames(
                                                item.current ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-indigo-600 hover:bg-opacity-75',
                                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                            )}
                                        >
                                            <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300" aria-hidden="true" />
                                            <span className="flex-1">{item.name}</span>

                                        </a>
                                    ))}
                                </nav>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

        </>

    )
}