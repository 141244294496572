import React from 'react';
import Hero from "../components/hero"
import Sidebar from "../components/sidebar"
import MyPopover from "../components/mypopover"
import AboutUS from '../components/about-us';
import Layout from "../components/layout"



const AboutUs = ({ location }) => {

    return (

        <Layout location={location}  >
            <AboutUS />
        </Layout>

    )
}

export default AboutUs